import React, { useState } from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useRegister } from "../hooks/useRegister";

const Register = () => {
  const { register } = useRegister();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const onClickRegister = () => {
    register(user);
  };

  return (
    <>
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            新規登録画面
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            name="username"
            label="名前"
            id="username"
            type="text"
            required
            onChange={handleChange}
          ></TextField>
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            id="password"
            type="password"
            required
            onChange={handleChange}
          ></TextField>
          <Button
            variant="contained"
            fullWidth
            color="error"
            sx={{ mt: 3, mb: 2 }}
            type="onClick"
            onClick={onClickRegister}
          >
            新規登録
          </Button>
          <Link to="/login">登録済みの方はこちら</Link>
        </Box>
      </Container>
    </>
  );
};

export default Register;
