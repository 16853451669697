import React, { useState } from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useLogin } from "../hooks/useLogin";

const Login = () => {
  const { login } = useLogin();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const onClickLogin = () => {
    login(user);
  };

  return (
    <>
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            ログイン画面
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            name="username"
            label="名前"
            id="username"
            type="text"
            required
            onChange={handleChange}
          ></TextField>
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            id="password"
            type="password"
            required
            onChange={handleChange}
          ></TextField>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            type="onClick"
            onClick={onClickLogin}
          >
            ログイン
          </Button>
          <Link to="/register">新規登録はこちら</Link>
        </Box>
      </Container>
    </>
  );
};

export default Login;
