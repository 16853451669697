import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LoginUserContext } from "../providers/LoginUserProvider";

export const useLogin = () => {
  const { setLoginUser, setIsLogined } = useContext(LoginUserContext);
  const navigate = useNavigate();
  const login = (user) => {
    const endpoint = "http://35.77.230.107/users";
    const queries = { name: user.username, password: user.password };

    console.log("ボタンが押されたよ！");
    axios
      .get(endpoint, { params: queries })
      .then((res) => {
        console.log(res.data);
        console.log(res.data[0]);
        console.log(res.data.name);
        console.log(user.username);
        if (Object.keys(res.data).length > 0) {
          console.log("ログイン成功！");
          setLoginUser(res.data.name);
          setIsLogined(true);
          navigate("/", {
            state: { username: "。このまま学習を頑張りましょう！" },
          });
        } else {
          console.log("ログイン失敗");
          setLoginUser({ username: "", password: "" });
          navigate("/loginfailed");
        }
      })
      .catch((e) => {
        console.log(e);
        navigate("/loginfailed");
      });
  };
  return { login };
};
