import React from "react";
import axios from "axios";

export const useReadSales = () => {
  const baseEndpoint = "http://35.77.230.107/sales";
  const onClickReadSales = async (year) => {
    const endpoint = baseEndpoint + year;
    const res = await axios.get(endpoint);
    return res.data;
  };
  return { onClickReadSales };
};
