import React from "react";

const LoginFailed = () => {
  return (
    <>
      <div>ログイン失敗</div>
    </>
  );
};

export default LoginFailed;
