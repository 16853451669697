import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import LoginFailed from "./components/pages/LoginFailed";
import NotFound from "./components/pages/NotFound";
import Register from "./components/pages/Register";
import RegisterSucceded from "./components/pages/RegisterSucceded";
import RegisterFailed from "./components/pages/RegisterFalied";
import { LoginUserProvider } from "./components/providers/LoginUserProvider";
import Footer from "./components/templates/Footer";

function App() {
  return (
    <LoginUserProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/loginfailed" element={<LoginFailed />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route
            path="/registersucceded"
            element={<RegisterSucceded />}
          ></Route>
          <Route path="/registerfailed" element={<RegisterFailed />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </div>
      <Footer />
    </LoginUserProvider>
  );
}

export default App;
