import React from "react";
import { Box, InputLabel, MenuItem, Select } from "@mui/material";

const SelectYear = (props) => {
  const { handleYearChange } = props;
  const handleChange = (e) => {
    handleYearChange(e.target.value);
  };
  return (
    <div>
      <Box sx={{ mt: 2, width: "100%" }}>
        <InputLabel id="sales-year">年度</InputLabel>
        <Select
          labelId="sales-year"
          onChange={handleChange}
          fullWidth
          name="year"
          deflautValue={""}
        >
          <MenuItem value={2018}>2018</MenuItem>
          <MenuItem value={2019}>2019</MenuItem>
          <MenuItem value={2020}>2020</MenuItem>
          <MenuItem value={2021}>2021</MenuItem>
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
        </Select>
      </Box>
    </div>
  );
};

export default SelectYear;
