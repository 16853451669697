import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Container, Box, Typography } from "@mui/material";

const RegisterFailed = () => {
  const { state } = useLocation();
  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          登録に失敗しました。
        </Typography>
        <Typography component="h1" variant="h5">
          管理者にご連絡ください。
        </Typography>
        <Typography component="h1" variant="h5">
          なまえ：{state.username}
        </Typography>
        <Typography component="h1" variant="h5">
          パスワード：{state.password}
        </Typography>
        <Link to="/login">ログイン画面へ戻る</Link>
      </Box>
    </Container>
  );
};

export default RegisterFailed;
